<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Promociones</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <PromocionesTables :lstPromociones="lstPromociones" />
      </div>
      <div v-if="btn" class="col-12"><PromocionesForm /></div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, provide, reactive, ref } from "vue-demi";
import PromocionesTables from "../components/PromocionesTables.vue";
import PromocionesForm from "../components/PromocionesForm.vue";
import { useStore } from "vuex";
import usePromociones from "../composable/usePromociones";

export default {
  components: {
    PromocionesTables,
    PromocionesForm,
  },

  setup() {
    const btn = inject("btn");
    const store = useStore();
    const imgUrlPostCurso = ref("");
    const imgUrlPostGanadores = ref("");
    const imgUrlBtn1 = ref("");
    const imgUrlBtn2 = ref("");
    const imgUrlBtn3 = ref("");
    const { getPromociones, lstPromociones } = usePromociones();
    const boxPromociones = reactive({
      activo: 0,
      inactivo: 0,
      total: 0,
      titulo: "Promociones",
    });

    const objPromociones = reactive({
      id: null,
      fecha_ini: null,
      fecha_fin: null,
      fecha_cierre: null,
      img_btn_1: "",
      img_btn_2: "",
      img_btn_3: "",
      img_post_1: "",
      img_post_2: "",
      mostrar: "",
      status: "",
      orden: "",
      bases: "",
      titulo: "",
      enlace: "",
    });

    onMounted(async () => {
      store.commit("showLoader");
      await getPromociones({ perPage: 5 });
      store.commit("hideLoader");
    });

 

    provide("objPromociones", objPromociones);
    provide("imgUrlPostCurso", imgUrlPostCurso);
    provide("imgUrlPostGanadores", imgUrlPostGanadores);
    provide("imgUrlBtn1", imgUrlBtn1);
    provide("imgUrlBtn2", imgUrlBtn2);
    provide("imgUrlBtn3", imgUrlBtn3);

    return {
      btn,
      lstPromociones,
      boxPromociones,
    };
  },
};
</script>

<style>
</style>