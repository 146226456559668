import { computed } from "vue";
import { useStore } from "vuex";

const usePromociones = () => {
  const store = useStore();

  const createPromociones = async (obj) => {
    const resp = await store.dispatch("promociones/createPromociones", obj);
    return resp;
  };
  const editPromociones = async (obj) => {
    const resp = await store.dispatch("promociones/editPromociones", obj);
    return resp;
  };
  const deletePromociones = async (obj) => {
    const resp = await store.dispatch("promociones/deletePromociones", obj);
    return resp;
  };
  const getPromociones = async (obj) => {
    const resp = await store.dispatch("promociones/getPromociones", obj);
    return resp;
  };

  return {
    createPromociones,
    editPromociones,
    deletePromociones,
    getPromociones,
    lstPromociones: computed(() => store.getters["promociones/getPromociones"]),
  };
};

export default usePromociones;
