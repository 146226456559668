<template>
  <div class="row">
    <div class="col-md-2">
      <div class="input-group mb-3">
        <label class="input-group-text" for="inputGroupSelect01">Paginas</label>
        <select
          class="form-select"
          id="inputGroupSelect01"
          v-model="perPage"
          @change="changeNumerPage"
        >
          <option value="5">5</option>
          <option value="10" v-if="pageConfi > 1">10</option>
          <option value="15" v-if="pageConfi > 2">15</option>
          <option value="20" v-if="pageConfi > 3">20</option>
          <option value="25" v-if="pageConfi > 4">25</option>
          <option value="30" v-if="pageConfi > 5">30</option>
          <option value="50" v-if="pageConfi > 6">50</option>
        </select>
      </div>
    </div>
    <div class="col-md-10">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end" v-if="data">
          <li
            style="cursor: pointer"
            v-for="p of data"
            v-bind:key="p.id"
            :class="{
              'page-item': true,
              active: p.active,
            }"
          >
            <a class="page-link" @click="pagina(p.url)">{{
              labelPaginator(p.label)
            }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "endpoint", "set", "parametros"],
  data() {
    return {
      perPage: 5,
      page: 1,
      pageConfi: 0,
    };
  },
  created() {
    this.perPage = 5; 
    localStorage.setItem('perPage',this.perPage)
  },
  watch: {  

    
  },
  methods: {
    labelPaginator(e) {
      if (e == "&laquo; Previous") {
        return "Previous";
      }
      if (e == "Next &raquo;") {
        return "Next";
      }
      return e;
    },
    async pagina(e) {            
      if (!e) {
        return true;
      }
      this.page = e.split("=")[1];
      this.consultarData();
    },
    async consultarData() {   
      let objUrl = {
        url: `${this.endpoint}?perPage=${this.perPage}&page=${this.page}${this.parametros}`,
        set: this.set,
      };
      
      await this.$store.dispatch("getData", objUrl);
    },
    async changeNumerPage() {
      localStorage.setItem('perPage',this.perPage)
      this.page = 1;
      this.consultarData();
    },
  },
  async mounted() {
    this.labelPaginator();
       this.data.forEach((element) => {
      if (
        element.label != "&laquo; Previous" &&
        element.label != "Next &raquo;"
      ) {
        this.pageConfi++;
      }
    });
  },
};
</script>

<style>
</style>