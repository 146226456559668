<template>
  <div class="text-end mt-2 mb-2">
    <button class="myButtonAceptar" @click="agregarPromociones">Agregar</button>
  </div>

  <div class="card table-responsive-sm" v-if="lstPromociones">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Titulo</th>
          <th scope="col">Fecha inicio</th>
          <th scope="col">Hora inicio</th>
          <th scope="col">Fecha fin</th>
          <th scope="col">Hora fin</th>
          <th scope="col">Mostrar</th>
          <th scope="col">Status</th>
          <th scope="col">Orden</th>
          <th scope="col">Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="d of lstPromociones.data" v-bind:key="d.id">
        <tr style="cursor: pointer">
          <td @click="select(d)">{{ d.titulo }}</td>
          <td @click="select(d)">{{ dateFormat(d.fecha_ini) }}</td>
          <td @click="select(d)">{{ timeFormat(d.fecha_ini) }}</td>
          <td @click="select(d)">{{ dateFormat(d.fecha_fin) }}</td>
          <td @click="select(d)">{{ timeFormat(d.fecha_fin) }}</td>
          <td @click="select(d)">{{ mostrar(d.mostrar) }}</td>
          <td @click="select(d)">{{ estatus(d.status) }}</td>
          <td @click="select(d)">{{ d.orden }}</td>
          <td>
            <button class="btnEliminarTable" @click="eliminarPromociones(d)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <Paginador
      :data="lstPromociones.links"
      endpoint="/api/v1/getPromociones"
      set="promociones/setPromociones"
      parametros=""
    />
  </div>
</template>

<script>
import { inject } from "vue-demi";
import useUtil from "../../../use/useUtil";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import usePromociones from "../composable/usePromociones";
import Paginador from "../../../components/Paginador.vue";

export default {
  props: ["lstPromociones"],
  components: {
    Paginador,
  },
  setup() {
    const objPromociones = inject("objPromociones");
    const btn = inject("btn");
    const { dateFormat, timeFormat, dateFormatInput, timeFormatInput } =
      useUtil();
    const imgUrlPostCurso = inject("imgUrlPostCurso");
    const imgUrlPostGanadores = inject("imgUrlPostGanadores");
    const imgUrlBtn1 = inject("imgUrlBtn1");
    const imgUrlBtn2 = inject("imgUrlBtn2");
    const imgUrlBtn3 = inject("imgUrlBtn3");
    const store = useStore();
    const { deletePromociones, getPromociones } = usePromociones();

    const agregarPromociones = () => {
      objPromociones.id = null;
      objPromociones.fecha_ini = null;
      objPromociones.fecha_fin = null;
      objPromociones.fecha_cierre = null;
      objPromociones.img_btn_1 = null;
      objPromociones.img_btn_2 = null;
      objPromociones.img_btn_3 = null;
      objPromociones.img_post_1 = null;
      objPromociones.img_post_2 = null;
      objPromociones.mostrar = true;
      objPromociones.status = 1;
      objPromociones.orden = 0;
      objPromociones.bases = null;
      objPromociones.titulo = null;
      objPromociones.enlace = null;

      imgUrlPostCurso.value = null;
      imgUrlPostGanadores.value = null;
      imgUrlBtn1.value = null;
      imgUrlBtn2.value = null;
      imgUrlBtn3.value = null;
      btn.value = true;
    };

    const mostrar = (e) => {
      if (e) {
        return "Si";
      }
      return "No";
    };

    const estatus = (e) => {
      let resul = "";
      switch (e) {
        case 1:
          resul = "Concurso";
          break;
        case 2:
          resul = "Concursantes";
          break;
        case 3:
          resul = "Ganadores";
          break;
      }
      return resul;
    };

    const select = (e) => {
      objPromociones.id = e.id;
      objPromociones.fecha_ini =
        dateFormatInput(e.fecha_ini) + "T" + timeFormatInput(e.fecha_ini);
      objPromociones.fecha_fin =
        dateFormatInput(e.fecha_fin) + "T" + timeFormatInput(e.fecha_fin);
      objPromociones.fecha_cierre =
        dateFormatInput(e.fecha_cierre) + "T" + timeFormatInput(e.fecha_cierre);
      // imgUrlBtn1.value = e.img_btn_1
      //   ? process.env.VUE_APP_URL_IMG + e.img_btn_1
      //   : null;
      // imgUrlBtn2.value = e.img_btn_2
      //   ? process.env.VUE_APP_URL_IMG + e.img_btn_2
      //   : null;
      // imgUrlBtn3.value = e.img_btn_3
      //   ? process.env.VUE_APP_URL_IMG + e.img_btn_3
      //   : null;

      // imgUrlPostCurso.value = e.img_post_1
      //   ? process.env.VUE_APP_URL_IMG + e.img_post_1
      //   : null;
      // imgUrlPostGanadores.value = e.img_post_2
      //   ? process.env.VUE_APP_URL_IMG + e.img_post_2
      //   : null;

      objPromociones.img_btn_1 = e.img_btn_1;
      objPromociones.img_btn_2 = e.img_btn_2;
      objPromociones.img_btn_3 = e.img_btn_3;
      objPromociones.img_post_1 = e.img_post_1;
      objPromociones.img_post_2 = e.img_post_2;

      objPromociones.mostrar = e.mostrar;
      objPromociones.status = e.status;
      objPromociones.orden = e.orden;
      objPromociones.bases = e.bases;
      objPromociones.titulo = e.titulo;
      objPromociones.enlace = e.enlace;
      objPromociones.deleted = e.deleted;
      btn.value = true;
    };

    const eliminarPromociones = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objPromociones.id = e.id;
          store.commit("showLoader");
          await deletePromociones(objPromociones);
          await getPromociones({ perPage: 5 });
          store.commit("hideLoader");

          return true;
        }
      });
    };

    return {
      agregarPromociones,
      dateFormat,
      timeFormat,
      mostrar,
      estatus,
      select,
      eliminarPromociones,
    };
  },
};
</script>

<style>
</style>