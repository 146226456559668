<template>
  <form @submit.prevent="submitPromociones" class="promocionesForm">
    <div class="text-end text-light">
      <Cerrar />
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class="text-light">Fecha y hora inicio</label>
        <input
          v-model="objPromociones.fecha_ini"
          type="datetime-local"
          class="form-control"
        />
        <small class="form-text txt-red" v-if="v$.fecha_ini.$error">
          *Debe colocar una fecha de inicio.
        </small>
      </div>
      <div class="col-md-3">
        <label class="text-light">Fecha y hora fin</label>
        <input
          v-model="objPromociones.fecha_fin"
          type="datetime-local"
          class="form-control"
        />
        <small class="form-text txt-red" v-if="v$.fecha_fin.$error">
          *Debe colocar una fecha de fin.
        </small>
      </div>
      <div class="col-md-1">
        <label class="text-light">Mostrar</label>
        <div class="form-check form-switch text-center">
          <input
            v-model="objPromociones.mostrar"
            class="form-check-input"
            type="checkbox"
            :checked="objPromociones.mostrar"
          />
          <label
            v-if="objPromociones.mostrar"
            class="form-check-label text-light"
            >SI</label
          >
          <label v-else class="form-check-label text-light">NO</label>
        </div>
      </div>
      <!-- <div class="col-md-2">
        <label class="text-light">Estatus</label>
        <select
          name=""
          id=""
          class="form-select"
          v-model="objPromociones.status"
        >
          <option value="1">Concurso</option>
          <option value="2">Concursantes</option>
          <option value="3">Ganadores</option>
        </select>
      </div> -->
      <div class="col-md-1">
        <label class="text-light">Orden</label>
        <select
          name=""
          id=""
          class="form-select"
          v-model="objPromociones.orden"
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </select>
      </div>
      <div class="col-md-1">
        <!-- <label class="text-light">Ganadores</label> -->
        <div class="form-check">
          <input
            class="form-check-input text-center"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            v-model="checkGanadores"
            @change="changeGanadores"
          />
          <label class="form-check-label text-light" for="flexCheckDefault">
            Ganadores
          </label>
        </div>
      </div>
    </div>

    <div class="row py-2">
      <div class="col-md-6">
        <label class="text-light">Enlace</label>
        <input
          v-model="objPromociones.enlace"
          type="text"
          class="form-control"
          name=""
          id=""
        />
      </div>
      <div class="col-md-6">
        <label class="text-light">Titulo</label>
        <input
          v-model="objPromociones.titulo"
          type="text"
          class="form-control"
          name=""
          id=""
        />
        <small class="form-text txt-red" v-if="v$.titulo.$error">
          *Debe colocar un titulo.
        </small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label class="text-light">Post Concurso</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objPromociones.img_post_1"
            @click="pegarImagen('img_post_1')"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            @click="limpiarInput('img_post_1')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('img_post_1')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
        <img
          :src="objPromociones.img_post_1 || 'img/no-img.jpg'"
          alt=""
          id="fileCurso"
          class="img-fluid img-promociones mt-2"
          style="cursor: pointer"
        />
      </div>
      <div class="col-md-3">
        <label class="text-light">Post Ganadores</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objPromociones.img_post_2"
            @click="pegarImagen('img_post_2')"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            id="button-addon2"
            @click="limpiarInput('img_post_2')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('img_post_2')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
        <img
          :src="objPromociones.img_post_2 || 'img/no-img.jpg'"
          alt=""
          id="fileCurso"
          class="img-fluid img-promociones mt-2"
          style="cursor: pointer"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-3">
        <label class="text-light">Imagen Btn 1</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objPromociones.img_btn_1"
            @click="pegarImagen('img_btn_1')"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            id="button-addon2"
            @click="limpiarInput('img_btn_1')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('img_btn_1')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
        <img
          :src="objPromociones.img_btn_1 || 'img/no-img.jpg'"
          alt=""
          id="fileCurso"
          class="img-fluid img-promociones mt-2"
          style="cursor: pointer"
        />
      </div>
      <div class="col-md-3">
        <label class="text-light">Imagen Btn 2</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objPromociones.img_btn_2"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            id="button-addon2"
            @click="limpiarInput('img_btn_2')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('img_btn_2')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
        <img
          :src="objPromociones.img_btn_2 || 'img/no-img.jpg'"
          alt=""
          id="fileCurso"
          class="img-fluid img-promociones mt-2"
          style="cursor: pointer"
        />
      </div>
      <div class="col-md-3">
        <label class="text-light">Imagen Btn 3</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="objPromociones.img_btn_3"
            @click="pegarImagen('img_btn_3')"
            disabled
          />
          <button
            class="btn btn-danger"
            type="button"
            id="button-addon2"
            @click="limpiarInput('img_btn_3')"
          >
            X
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click="pegarImagen('img_btn_3')"
          >
            <i class="fas fa-paste"></i>
          </button>
          <GaleriaBtn />
        </div>
        <img
          :src="objPromociones.img_btn_3 || 'img/no-img.jpg'"
          alt=""
          id="fileCurso"
          class="img-fluid img-promociones mt-2"
          style="cursor: pointer"
        />
      </div>
    </div>

    <div class="row justify-content-center py-2">
      <div class="col-md-2">
        <button class="myButtonAceptar">Guardar</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 py-3">
        <CKEditor @sendContent="getContent" :content="objPromociones.bases" />
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount, ref } from "vue-demi";
import CKEditor from "../../../components/CKEditor.vue";
import { useStore } from "vuex";
import Cerrar from "../../../components/button/Cerrar.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import usePromociones from "../composable/usePromociones";
import Swal from "sweetalert2";
import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";
import useUtil from "../../../use/useUtil";
export default {
  components: {
    CKEditor,
    Cerrar,
    GaleriaBtn,
  },
  setup() {
    const objPromociones = inject("objPromociones");
    const imgUrlPostCurso = inject("imgUrlPostCurso");
    const imgUrlPostGanadores = inject("imgUrlPostGanadores");
    const imgUrlBtn1 = inject("imgUrlBtn1");
    const imgUrlBtn2 = inject("imgUrlBtn2");
    const imgUrlBtn3 = inject("imgUrlBtn3");
    const btn = inject("btn");
    const checkGanadores = ref(false);
    const { onPegarTexto } = useUtil();
    const { createPromociones, getPromociones, editPromociones } =
      usePromociones();
    const store = useStore();

    const getContent = (val) => {
      objPromociones.bases = val;
    };

    const rules = {
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      titulo: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objPromociones);

    const submitPromociones = async () => {
      let resp = "";
      v$.value.$touch();

      store.commit("showLoader");

      if (v$.value.$invalid) {
        return false;
      }

      if (objPromociones.id) {
        validateStatus();
        resp = await editPromociones(objPromociones);
      } else {
        resp = await createPromociones(objPromociones);
      }

      await getPromociones({ perPage: 5 });
      store.commit("hideLoader");

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }

      cerrar();
    };

    const validateStatus = () => {
      if (objPromociones.status == 3) {
        checkGanadores.value = true;
      }
      if (!checkGanadores.value) {
        if (new Date(objPromociones.fecha_fin) >= new Date()) {
          objPromociones.status = 1;
        }
        if (new Date(objPromociones.fecha_fin) <= new Date()) {
          objPromociones.status = 2;
        }
      }
    };

    const changeGanadores = () => {
      if (checkGanadores.value) {
        objPromociones.status = 3;
      } else {
        objPromociones.status = 1;
      }
    };

    const limpiarInput = (val) => {
      switch (val) {
        case "img_btn_1":
          objPromociones.img_btn_1 = "";
          break;
        case "img_btn_2":
          objPromociones.img_btn_2 = "";
          break;
        case "img_btn_3":
          objPromociones.img_btn_3 = "";
          break;
        case "img_post_1":
          objPromociones.img_post_1 = "";
          break;
        case "img_post_2":
          objPromociones.img_post_2 = "";
          break;
      }
    };

    const pegarImagen = async (val) => {
      switch (val) {
        case "img_btn_1":
          objPromociones.img_btn_1 = "";
          objPromociones.img_btn_1 = await onPegarTexto();
          break;
        case "img_btn_2":
          objPromociones.img_btn_2 = "";
          objPromociones.img_btn_2 = await onPegarTexto();
          break;
        case "img_btn_3":
          objPromociones.img_btn_3 = "";
          objPromociones.img_btn_3 = await onPegarTexto();
          break;
        case "img_post_1":
          objPromociones.img_post_1 = "";
          objPromociones.img_post_1 = await onPegarTexto();
          break;
        case "img_post_2":
          objPromociones.img_post_2 = "";
          objPromociones.img_post_2 = await onPegarTexto();
          break;
      }
    };

    const cerrar = () => {
      btn.value = false;
    };

    validateStatus();

    onBeforeUnmount(() => {
      objPromociones.id = null;
      objPromociones.fecha_ini = null;
      objPromociones.fecha_fin = null;
      objPromociones.fecha_cierre = null;
      objPromociones.img_btn_1 = null;
      objPromociones.img_btn_2 = null;
      objPromociones.img_btn_3 = null;
      objPromociones.img_post_1 = null;
      objPromociones.img_post_2 = null;
      objPromociones.mostrar = true;
      objPromociones.status = 1;
      objPromociones.orden = 0;
      objPromociones.bases = null;
      objPromociones.titulo = null;
      objPromociones.enlace = null;

      imgUrlPostCurso.value = null;
      imgUrlPostGanadores.value = null;
      imgUrlBtn1.value = null;
      imgUrlBtn2.value = null;
      imgUrlBtn3.value = null;

      btn.value = false;
    });

    const subirImg = (e, img) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        switch (img) {
          case "concurso":
            objPromociones.img_post_1 = e.target.result;
            imgUrlPostCurso.value = URL.createObjectURL(file);
            break;

          case "ganadores":
            objPromociones.img_post_2 = e.target.result;
            imgUrlPostGanadores.value = URL.createObjectURL(file);
            break;

          case "btn1":
            objPromociones.img_btn_1 = e.target.result;
            imgUrlBtn1.value = URL.createObjectURL(file);
            break;

          case "btn2":
            objPromociones.img_btn_2 = e.target.result;
            imgUrlBtn2.value = URL.createObjectURL(file);
            break;

          case "btn3":
            objPromociones.img_btn_3 = e.target.result;
            imgUrlBtn3.value = URL.createObjectURL(file);
            break;
        }
      };
    };

    return {
      objPromociones,
      getContent,
      submitPromociones,
      imgUrlPostCurso,
      imgUrlPostGanadores,
      imgUrlBtn1,
      imgUrlBtn2,
      imgUrlBtn3,
      subirImg,
      cerrar,
      v$,
      checkGanadores,
      changeGanadores,
      limpiarInput,
      pegarImagen,
    };
  },
};
</script>

<style scoped>
.promocionesForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 100%;
  margin: auto;
}
.img-promociones {
  width: 300px;
}
</style>