<template>
  <button
    class="btn btn-secondary custom"
    type="button"
    style="border-radius: 0px"
    @click="modalGaleria = true"
  >
    <i class="fas fa-image" style="color: rgb(143 143 143);"></i>
  </button>

  <ModalGenerico :open="modalGaleria" @close="modalGaleria = !modalGaleria" :ancho="'100%'">
    <S3Galeria />
  </ModalGenerico>
</template>

<script>
import { inject } from "vue-demi";
import ModalGenerico from "../modal/modalGenerico.vue";
import S3Galeria from "../../modules/galeria/view/S3Galeria.vue";
export default {
  setup() {
   

    const modalGaleria = inject("modalGaleria")


    return { modalGaleria };
  },
  components: { ModalGenerico, S3Galeria },
};
</script>

<style scoped>

/* border-color: #b5b5b5;  */

/* color: rgb(143 143 143);  */

.custom{
  background: transparent;
}
.custom:hover{
  background: #fff;
}


</style>